@import url(https://fonts.googleapis.com/css2?family=MedievalSharp&display=swap);
body {
    margin: 0;
    font-family: 'Times New Roman', Times, serif;
  }
  
  .App {
    text-align: center;
  }
  
  #map {
    margin-top: 20px;
  }
body {
    margin: 0;
    padding: 0;
    font-family: 'MedievalSharp', cursive;
    background-color: #f4f4f4;
    color: #cecece;
    background-image: url(/static/media/medieval-background.0108e750.jpg);
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
}

#map {
    height:75vh;
    width: 90%;
    margin: 5vh auto;
    margin-top: 10px;
    border: 5px solid #8B4513;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

header {
    text-align: center;
    padding: 20px;
    background-color: rgba(139, 69, 19, 0.8);
    border-bottom: 5px solid #8B4513;
    color: #fff;
}

h1 {
    margin: 10px;
    font-size: 3em;
}

footer {
    text-align: center;
    padding: 10px;
    background-color: rgba(139, 69, 19, 0.8);
    border-top: 5px solid #8B4513;
    color: #fff;
    position: absolute;
    bottom: 0;
    width: 100%;
}

a {
    color: #d4af37;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}
