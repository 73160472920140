body {
    margin: 0;
    font-family: 'Times New Roman', Times, serif;
  }
  
  .App {
    text-align: center;
  }
  
  #map {
    margin-top: 20px;
  }